import api from './api';

class ComboService {
    findRoleAll() {
        return api.get(`/api/v1/combo/findRoleAll`);
    }
    findAuthorityAll() {
        return api.get(`/api/v1/combo/findAuthorityAll`);
    }
    findUserMerchantsList() {
        return api.get(`/api/v1/combo/findUserMerchantsList`);
    }
    findBanksList() {
        return api.get(`/api/v1/combo/findBanksList`);
    }
    findUserAgent() {
        return api.get(`/api/v1/combo/findUserAgent`);
    }
}
export default new ComboService();
